import React, {useMemo} from 'react';
import {
  Button,
  Divider,
  Menu,
  MenuItem,
  Modal,
  Segment,
  Statistic,
  Tab,
} from 'semantic-ui-react';
import {
  QuoteAssetGetDto,
  QuoteAssetsService,
  QuotesService,
  QuoteTermGetDto,
  QuoteTermsService,
  UpdateQuoteTermRequest,
} from '../../api/generated';
import {Form} from '../../forms';
import {
  checkbox,
  dropdown,
  fieldConfig,
  input,
  currency,
  RawFieldConfig,
  datepicker,
} from '../../forms/schema-utils';
import {useNotification} from '../../hooks/use-notifications';
import {useState} from 'react';
import {useForm, useFormState} from 'react-final-form';
import {css} from '@emotion/core';
import {Flex} from '../../components/flex';
import Currency from '../../components/currency';
import {buildApiUrl, getEnumDropdownOptions} from '../../api/generated/utils';
import {
  QuoteStages,
  TermsPercentageOrDollar,
  TermsRegistrationTypes,
  TermsSalesTaxTypes,
} from '../../api/generated/enums';
import {CreateQuoteTermsFromTemplateButton} from '../../quote-term-templates/create-terms-from-templates-button';
import {QuoteStageProps} from '../quote-create-update';
import {CloneTermsButton} from '../clone-terms';
import {FindSalesTaxFormComponent} from '../sales-tax-modal';
import {useAsyncRetry} from 'react-use';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faExclamationTriangle,
  faPlus,
} from '@fortawesome/pro-solid-svg-icons';
import {currencyFormatter} from '../../assets/asset-utils';
import {ConfirmButton, DeleteButton} from '../../components/confirm-button';
import {QuoteStageFormActions} from './quote-stage-form-actions';
import {notifications} from '../../utils/notification-service';
import {useHistory, useRouteMatch} from 'react-router';
import {routes} from '../../routes';
import {buildPath} from '../../routes/utils';
import {getUrlForQuoteStage, QuoteStageInfo} from '../quote-steps';
import {cx} from 'emotion';

const MAX_TERMS_FOR_QUOTE_ASSET = 3;
const MAX_ASSETS_FOR_QUOTE = 3;
export const QuoteConfigureTerms: React.FC<QuoteStageProps> = ({
  quote,
  readOnly,
  refetchQuote,
}) => {
  const history = useHistory();
  const match = useRouteMatch<{id: string; assetId: string}>(
    routes.quotes.terms
  );
  const [submittingForReview, setSubmittingForReview] = useState(false);
  const quoteAssets = useAsyncRetry(async () => {
    const response = await QuoteAssetsService.getByQuoteId({
      id: quote.id,
      includeUnLocatedQuoteAssets: false,
    });
    return response.data;
  });

  const submitQuoteForReview = async () => {
    var response = await QuotesService.submitQuoteForReview({
      body: {quoteId: quote.id},
    });
    if (response.hasErrors) {
      notifications.errors(response.errors);
      setSubmittingForReview(false);
      return;
    }
    notifications.success('Quote submitted for review.');
    refetchQuote();
    history.push(
      buildPath(routes.quotes.detail, {
        id: quote.id,
        stage: getUrlForQuoteStage(QuoteStages.InReview),
      })
    );
  };

  const quoteAssetIndexForUrl =
    quoteAssets.value?.findIndex(
      (x) => x.id === parseInt(match?.params.assetId ?? '')
    ) ?? -1;

  const panes = useMemo(() => {
    const tabs = quoteAssets?.value
      ?.filter((x) => {
        return !readOnly || x.termsConfigured !== 0;
      })
      .map<{menuItem: any; render: any}>((asset) => {
        return {
          menuItem: (
            <MenuItem
              onClick={() => {
                history.push(
                  buildPath(routes.quotes.terms, {
                    id: quote.id,
                    assetId: asset.id,
                  })
                );
              }}
            >
              <Menu.Header>{asset.description}</Menu.Header>
              <Menu.Header className="sub">
                Cost: {currencyFormatter(asset.companyCost / 100)}
              </Menu.Header>
              <Menu.Header className="sub">
                {asset.termsConfigured === 0 && (
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    color="#e23a07"
                  />
                )}{' '}
                {asset.termsConfigured} Terms Configured
              </Menu.Header>
            </MenuItem>
          ),
          render: () => (
            <Tab.Pane key={asset.id}>
              <QuoteAssetTermsTabs
                quoteAsset={asset}
                refetchQuoteAssets={quoteAssets.retry}
                readOnly={readOnly}
                quoteId={quote.id}
              />
            </Tab.Pane>
          ),
        };
      });
    tabs &&
      (quoteAssets.value?.length ?? 0) < MAX_ASSETS_FOR_QUOTE &&
      !readOnly &&
      tabs.push({
        menuItem: (
          <MenuItem
            onClick={() => {
              history.push(buildPath(routes.quotes.newAsset, {id: quote.id}));
            }}
          >
            <Flex.Row justifyContent="justify-center" align="center">
              <p>
                <strong>
                  <FontAwesomeIcon
                    icon={faPlus}
                    css={css`
                      margin-right: 0.5rem;
                    `}
                  />
                  Add New Asset
                </strong>
              </p>
            </Flex.Row>
          </MenuItem>
        ),
        render: null,
      });
    return tabs;
  }, [quoteAssets.value]);
  return (
    <Segment>
      <Tab
        menu={{fluid: true, vertical: true}}
        menuPosition={'left'}
        panes={panes}
        activeIndex={quoteAssetIndexForUrl > 0 ? quoteAssetIndexForUrl : 0}
        css={css`
          .sub {
            font-size: 0.7em;
            font-weight: normal !important;
            color: grey;
          }
        `}
      />
      <Divider />
      <QuoteStageFormActions
        quoteId={quote.id}
        action={
          <>
            {!readOnly && (
              <ConfirmButton
                css={css`
                  margin-bottom: 1rem !important;
                `}
                type="button"
                primary
                basic={false}
                onConfirm={async () => {
                  if (
                    quoteAssets.value?.some(
                      (asset) => asset.termsConfigured === 0
                    )
                  ) {
                    setSubmittingForReview(true);
                  } else {
                    await submitQuoteForReview();
                  }
                }}
                text="Submit For Review"
              />
            )}
          </>
        }
      />
      <ConfirmUnconfiguredAssetsModal
        open={submittingForReview}
        onClose={() => {
          setSubmittingForReview(false);
        }}
        onConfirm={submitQuoteForReview}
      />
    </Segment>
  );
};
const QuoteAssetTermsTabs: React.FC<{
  quoteAsset: QuoteAssetGetDto;
  refetchQuoteAssets: () => void;
  readOnly?: boolean;
  quoteId: number;
}> = ({quoteAsset, refetchQuoteAssets, readOnly = false, quoteId}) => {
  const quoteAssetTerms = useAsyncRetry(async () => {
    const response = await QuoteTermsService.getAllByAssetId({
      id: quoteAsset.id,
    });
    return response.data;
  });

  const panes = useMemo(() => {
    const termTabs = quoteAssetTerms.value?.map<{
      menuItem: any;
      render: () => any;
    }>((term, index) => {
      return {
        menuItem: `Terms ${index + 1}`,
        render: () => (
          <QuoteConfigureTermsTab
            key={term.id}
            term={term}
            readOnly={readOnly}
            onTermsUpdated={() => {
              quoteAssetTerms.retry();
              refetchQuoteAssets();
            }}
            quoteId={quoteId}
          />
        ),
      };
    });

    if (
      (quoteAssetTerms.value?.length ?? 0) < MAX_TERMS_FOR_QUOTE_ASSET &&
      !readOnly
    ) {
      termTabs?.push({
        menuItem: (
          <MenuItem>
            <FontAwesomeIcon
              icon={faPlus}
              size={'lg'}
              css={css`
                margin-right: 0.5rem;
              `}
            />{' '}
            <strong>Add New Terms</strong>
          </MenuItem>
        ),
        render: () => {
          return (
            <NewQuoteTermsTab
              key={-1}
              termAssetId={quoteAsset.id}
              onTermsCreated={() => {
                quoteAssetTerms.retry();
                refetchQuoteAssets();
              }}
              quoteId={quoteId}
            />
          );
        },
      });
    }
    return termTabs;
  }, [quoteAssetTerms.value]);

  return <Tab panes={panes} />;
};

const QuoteConfigureTermsTab: React.FC<{
  term: QuoteTermGetDto;
  onTermsUpdated: () => void;
  readOnly?: boolean;
  quoteId: number;
}> = ({term, onTermsUpdated, readOnly = false, quoteId}) => {
  const notifications = useNotification();

  const onSubmit = async (values: QuoteTermGetDto) => {
    const response = await QuoteTermsService.update({
      id: term.id,
      body: {...values},
    });
    if (response.hasErrors) {
      notifications.error('There was an issue saving the terms.');
      return response;
    }
    onTermsUpdated();
    notifications.success('Calculations Completed');
  };

  const {fields, termsFormMutators} = useQuoteTermFormProps(readOnly);

  const handleDelete = async () => {
    const response = await QuoteTermsService.deleteById({id: term.id});
    if (response.hasErrors) {
      notifications.errors(response.errors);
    }
    notifications.success('Terms deleted.');
    onTermsUpdated();
  };

  return (
    <>
      <div css={styles}>
        <Form.Container>
          <Form
            initialValues={{...term}}
            onSubmit={onSubmit}
            mutators={termsFormMutators}
            render={() => (
              <>
                <FormFields
                  quoteAssetId={term.quoteAssetId}
                  onTermsCloned={onTermsUpdated}
                  readOnly={readOnly}
                  fields={fields}
                  quoteId={quoteId}
                />
                {!readOnly && (
                  <>
                    <Divider />
                    <Flex.Row justifyContent="space-between">
                      <Form.Button
                        type="submit"
                        content="Save & Calculate"
                        color="blue"
                      />
                      <DeleteButton
                        onConfirm={handleDelete}
                        loading={false}
                        text="Delete Term"
                      />
                    </Flex.Row>
                  </>
                )}
              </>
            )}
          />
        </Form.Container>
      </div>
    </>
  );
};

const NewQuoteTermsTab: React.FC<{
  onTermsCreated: () => void;
  termAssetId: number;
  quoteId: number;
}> = ({onTermsCreated, termAssetId, quoteId}) => {
  const notifications = useNotification();

  const onSubmit = async (values: QuoteTermGetDto) => {
    const response = await QuoteTermsService.create({
      body: {...values, quoteAssetId: termAssetId},
    });
    if (response.hasErrors) {
      notifications.error('There was an issue saving the terms.');
      return response;
    }
    onTermsCreated();
    notifications.success('Calculations Completed');
  };

  const {fields, termsFormMutators} = useQuoteTermFormProps(false);

  return (
    <Form.Container css={styles}>
      <Form
        onSubmit={onSubmit}
        // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
        mutators={termsFormMutators}
        initialValues={{showOnCustomerWorksheet: true}}
        render={() => (
          <>
            <FormFields
              quoteAssetId={termAssetId}
              fields={fields}
              quoteId={quoteId}
            />
            <Divider />
            <Flex.Row>
              <Form.Button
                type="submit"
                content="Save & Calculate"
                color="blue"
              />
            </Flex.Row>
          </>
        )}
      />
    </Form.Container>
  );
};

const TermsSummary: React.FC<{
  readOnly?: boolean;
  capitalizedCost?: number;
  term?: number;
  dueOnDelivery?: number;
  monthlyPayment?: number;
}> = ({capitalizedCost, dueOnDelivery, monthlyPayment, readOnly, term}) => (
  <div className="calculations">
    <Flex.Row justifyContent="space-between" align="center">
      {!readOnly && (
        <Flex.Col>
          <Form.Button type="submit" content="Save & Calculate" color="blue" />
        </Flex.Col>
      )}
      <Flex.Col>
        <Statistic size="mini">
          <Statistic.Label>Capitalized Cost</Statistic.Label>
          <Statistic.Value>
            <Currency amount={(capitalizedCost ?? 0) / 100} />
          </Statistic.Value>
        </Statistic>
      </Flex.Col>
      <Flex.Col>
        <Statistic size="mini">
          <Statistic.Label>Term Months</Statistic.Label>
          <Statistic.Value>{term ?? 0}</Statistic.Value>
        </Statistic>
      </Flex.Col>
      <Flex.Col>
        <Statistic size="mini">
          <Statistic.Label>Monthly Payment</Statistic.Label>
          <Statistic.Value>
            <Currency amount={(monthlyPayment ?? 0) / 100} />
          </Statistic.Value>
        </Statistic>
      </Flex.Col>
      <Flex.Col>
        <Statistic size="mini">
          <Statistic.Label>Due On Delivery</Statistic.Label>
          <Statistic.Value>
            <Currency amount={(dueOnDelivery ?? 0) / 100} />
          </Statistic.Value>
        </Statistic>
      </Flex.Col>
    </Flex.Row>
  </div>
);

const FormFields: React.FC<{
  quoteAssetId: number;
  onTermsCloned?: () => void;
  readOnly?: boolean;
  fields: RawFieldConfig<ConfigureTerms>;
  quoteId: number;
}> = ({quoteAssetId, onTermsCloned, readOnly, fields, quoteId}) => {
  const {initialValues} = useFormState<QuoteTermGetDto>();
  const {mutators} = useForm();

  return (
    <>
      <TermsSummary
        readOnly={readOnly}
        term={initialValues?.term ?? 0}
        capitalizedCost={initialValues?.capitalizedCost ?? 0}
        monthlyPayment={initialValues?.monthlyPayment ?? 0}
        dueOnDelivery={initialValues?.dueOnDelivery ?? 0}
      />
      {!readOnly && (
        <Flex.Row align="center" justifyContent="flex-end">
          <Flex.Col>
            {initialValues?.id && (
              <CloneTermsButton
                quoteTermId={initialValues.id}
                onTermsCloned={onTermsCloned}
              />
            )}
          </Flex.Col>
          <Flex.Col>
            <CreateQuoteTermsFromTemplateButton
              quoteAssetId={quoteAssetId}
              onTemplateSelected={(term) => {
                mutators.setAllInitialValues({...term});
              }}
            />
          </Flex.Col>
        </Flex.Row>
      )}
      <QuoteTermFields
        autoFocus
        fields={fields}
        quoteId={quoteId}
        canDownloadLeaseWorksheet={!!initialValues.id}
        onResetTaxClicked={() => {
          mutators.clearTax();
        }}
        readonly={readOnly}
      />
    </>
  );
};

export const QuoteTermFields: React.FC<{
  autoFocus?: boolean;
  fields: RawFieldConfig<ConfigureTerms>;
  quoteId?: number;
  canDownloadLeaseWorksheet?: boolean;
  onResetTaxClicked?: () => void;
  readonly?: boolean;
}> = ({
  autoFocus,
  fields,
  quoteId,
  canDownloadLeaseWorksheet,
  onResetTaxClicked,
  readonly,
}) => {
  const {values} = useFormState<ConfigureTerms>();

  const leaseWorksheetUrl = buildApiUrl<
    typeof QuotesService.downloadLeaseWorksheet
  >('/api/quotes/lease-worksheet', {quoteId: quoteId});

  return (
    <>
      {fields && (
        <>
          <Form.Checkbox fieldConfig={fields.showOnCustomerWorksheet} />
          {canDownloadLeaseWorksheet && (
            <Button
              icon
              type="button"
              onClick={() => {
                window.open(leaseWorksheetUrl, '_blank');
              }}
            >
              <FontAwesomeIcon icon={faDownload} /> Lease Worksheet
            </Button>
          )}
          <Form.Section title="Cost Structure">
            <Form.Row proportions={[1, 1, 1, 1]}>
              <Form.InputCurrency fieldConfig={fields.markupProfit} />
              <Form.InputCurrency fieldConfig={fields.capAmountUpfit} />
              <Form.InputCurrency
                fieldConfig={fields.capAmountTransportAmount}
              />
              <Form.InputCurrency fieldConfig={fields.capAmountLeaseOrigFee} />
            </Form.Row>
            <Form.Row proportions={[1, 1, 1, 1]}>
              <Form.InputCurrency fieldConfig={fields.capAmountDocFee} />
              <Form.InputCurrency fieldConfig={fields.capAmountProrate} />
              <Form.InputCurrency fieldConfig={fields.capAmountPostageFee} />
              <Form.InputCurrency fieldConfig={fields.capAmountEquity} />
            </Form.Row>
            <Form.Row proportions={[1, 1, 1, 1]}>
              <Form.Input
                fieldConfig={fields.capAmountAdditionalFeeDescription1}
              />
              <Form.InputCurrency
                fieldConfig={fields.capAmountAdditionalFeeAmount1}
              />
              <Form.Input
                fieldConfig={fields.capAmountAdditionalFeeDescription2}
              />
              <Form.InputCurrency
                fieldConfig={fields.capAmountAdditionalFeeAmount2}
              />
            </Form.Row>
            <Divider />
            <Form.Row>
              <Flex.Col justifyContent={'justify-center'}>
                <FindSalesTaxFormComponent
                  disabled={!!values.salesTaxOverride || readonly}
                />
              </Flex.Col>
              <Form.Checkbox
                fieldConfig={fields.salesTaxOverride}
                onClick={() => {
                  onResetTaxClicked && onResetTaxClicked();
                }}
              />
            </Form.Row>
            <Divider hidden />
            <Form.Row proportions={[1, 1, 1, 1]}>
              {values.salesTaxOverride ? (
                <>
                  <Form.InputDecimal
                    fieldConfig={fields.salesTaxOverrideAmount}
                    disabled={!values.salesTaxOverride}
                  />
                  <Form.Dropdown fieldConfig={fields.salesTaxType} />
                </>
              ) : (
                <>
                  <Form.InputDecimal fieldConfig={fields.salesTax} />
                  <Form.Dropdown fieldConfig={fields.salesTaxType} />

                  <Flex.Col className="form-field" justifyContent="flex-end">
                    <Button
                      type="button"
                      content="Reset"
                      onClick={onResetTaxClicked}
                      disabled={readonly}
                    />
                  </Flex.Col>
                </>
              )}
            </Form.Row>
            <Form.Row proportions={[1, 1, 2]}>
              <Form.InputCurrency fieldConfig={fields.registration} />
              <Form.Dropdown fieldConfig={fields.registrationType} />
            </Form.Row>
            <Divider hidden />
            <Form.Row proportions={[1, 1, 2]}>
              <Form.InputCurrency fieldConfig={fields.capCostReduction} />
              <Form.Dropdown fieldConfig={fields.capCostReductionType} />
            </Form.Row>
          </Form.Section>
          <Divider />
          <Form.Section title="Delivery Payment">
            <Form.Row proportions={[1, 1, 1, 1]}>
              <Form.InputCurrency
                fieldConfig={fields.dueOnDeliverySecurityDeposit}
              />
              <Form.InputCurrency
                fieldConfig={fields.dueOnDeliveryOtherDeposit}
              />
              <Form.InputCurrency fieldConfig={fields.dueOnDeliveryUpfit} />
              <Form.InputCurrency
                fieldConfig={fields.dueOnDeliveryTransportAmount}
              />
            </Form.Row>
            <Form.Row proportions={[1, 1, 1, 1]}>
              <Form.InputCurrency
                fieldConfig={fields.dueOnDeliveryLeaseOrigFee}
              />
              <Form.InputCurrency fieldConfig={fields.dueOnDeliveryTms} />
              <Form.InputCurrency fieldConfig={fields.dueOnDeliveryInsurance} />
              <Form.InputCurrency fieldConfig={fields.dueOnDeliveryGps} />
            </Form.Row>
            <Form.Row proportions={[1, 1, 1, 1]}>
              <Form.Input
                fieldConfig={fields.dueOnDeliveryAdditionalFeeDescription1}
              />
              <Form.InputCurrency
                fieldConfig={fields.dueOnDeliveryAdditionalFeeAmount1}
              />
              <Form.Input
                fieldConfig={fields.dueOnDeliveryAdditionalFeeDescription2}
              />
              <Form.InputCurrency
                fieldConfig={fields.dueOnDeliveryAdditionalFeeAmount2}
              />
            </Form.Row>
            <Form.Row proportions={[1, 3]}>
              <Form.InputCurrency fieldConfig={fields.lessTradeInEquity} />
            </Form.Row>
          </Form.Section>
          <Divider />
          <Form.Section title="Finance">
            <Form.Row proportions={[1, 1, 1, 1]}>
              <Form.Input fieldConfig={fields.term} />
              <Form.InputDecimal fieldConfig={fields.apr} />
              <Form.InputCurrency fieldConfig={fields.cashFlow} />
            </Form.Row>
            <Form.Row proportions={[1, 1, 1, 1]}>
              <Form.DatePicker fieldConfig={fields.contractBeginDate} />
              <Form.Checkbox fieldConfig={fields.calculateProrate} />
            </Form.Row>
            <Form.Row proportions={[1, 1, 2]}>
              <Form.InputCurrency fieldConfig={fields.msrp} />
              <Form.InputCurrency fieldConfig={fields.invoice} />
            </Form.Row>
          </Form.Section>
        </>
      )}
    </>
  );
};

export const ConfirmUnconfiguredAssetsModal: React.FC<{
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}> = ({open, onClose, onConfirm}) => {
  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Modal.Header>Unconfigured Assets</Modal.Header>
        <Modal.Content>
          There are assets without configured terms. Would you still like to
          proceed?
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={onConfirm}>
            Yes, Proceed to Review
          </Button>
          <Button secondary onClick={onClose}>
            No
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

type ConfigureTerms = Omit<
  UpdateQuoteTermRequest,
  | 'id'
  | 'quoteAssetId'
  | 'capitalizedCost'
  | 'residualValue'
  | 'monthlyPayment'
  | 'dueOnDelivery'
  | 'state'
  | 'paymentCalcCode'
>;

export const useQuoteTermFormProps = (readOnly: boolean) => {
  const fields = fieldConfig<ConfigureTerms>({
    markupProfit: currency({
      fieldLabel: 'Markup Profit',
      inputProps: {
        disabled: readOnly,
      },
    }),
    salesTax: input({
      fieldLabel: 'Sales Tax',
      inputProps: {
        disabled: true,
      },
    }),
    salesTaxType: dropdown({
      fieldLabel: 'Type',
      inputProps: {
        disabled: readOnly,
        selection: true,
        search: true,
        clearable: true,
        placeholder: 'Sales Tax Type...',
        options: getEnumDropdownOptions(TermsSalesTaxTypes),
      },
    }),
    salesTaxOverride: checkbox({
      fieldLabel: 'Override Sales Tax?',
      inputProps: {
        toggle: true,
        disabled: readOnly,
      },
    }),
    salesTaxOverrideAmount: input({
      fieldLabel: 'Sales Tax Override',
      inputProps: {
        disabled: readOnly,
      },
    }),
    stateTaxId: input({
      fieldLabel: '',
      inputProps: {
        disabled: readOnly,
      },
    }),
    countyTaxId: input({
      fieldLabel: '',
      inputProps: {
        disabled: readOnly,
      },
    }),
    cityTaxId: input({
      fieldLabel: '',
      inputProps: {
        disabled: readOnly,
      },
    }),
    registration: currency({
      fieldLabel: 'Registration',
      inputProps: {
        disabled: readOnly,
      },
    }),
    registrationType: dropdown({
      fieldLabel: 'Type',
      inputProps: {
        disabled: readOnly,
        selection: true,
        search: true,
        clearable: true,
        placeholder: 'Registration Type...',
        options: getEnumDropdownOptions(TermsRegistrationTypes),
      },
    }),
    capAmountUpfit: currency({
      fieldLabel: 'Upfit',
      inputProps: {
        disabled: readOnly,
      },
    }),
    capAmountTransportAmount: currency({
      fieldLabel: 'Transport Amt',
      inputProps: {
        disabled: readOnly,
      },
    }),
    capAmountLeaseOrigFee: currency({
      fieldLabel: 'Lease Orig Fee',
      inputProps: {
        disabled: readOnly,
      },
    }),
    capAmountDocFee: currency({
      fieldLabel: 'Doc Fee',
      inputProps: {
        disabled: readOnly,
      },
    }),
    capAmountProrate: currency({
      fieldLabel: 'Prorate',
      inputProps: {
        disabled: readOnly,
      },
    }),
    capAmountPostageFee: currency({
      fieldLabel: 'Postage Fee',
      inputProps: {
        disabled: readOnly,
      },
    }),
    capAmountEquity: currency({
      fieldLabel: 'Deficit/Equity',
      inputProps: {
        disabled: readOnly,
      },
    }),
    capAmountAdditionalFeeDescription1: input({
      fieldLabel: 'Additional Fee Description 1',
      inputProps: {
        disabled: readOnly,
      },
    }),
    capAmountAdditionalFeeAmount1: currency({
      fieldLabel: 'Additional Fee Amount 1',
      inputProps: {
        disabled: readOnly,
      },
    }),
    capAmountAdditionalFeeDescription2: input({
      fieldLabel: 'Additional Fee Description 2',
      inputProps: {
        disabled: readOnly,
      },
    }),
    capAmountAdditionalFeeAmount2: currency({
      fieldLabel: 'Additional Fee Amount 2',
      inputProps: {
        disabled: readOnly,
      },
    }),
    capCostReduction: currency({
      fieldLabel: 'Cap Cost Reduction',
      inputProps: {
        type: 'number',
        disabled: readOnly,
      },
    }),
    capCostReductionType: dropdown({
      fieldLabel: 'Type',
      inputProps: {
        selection: true,
        search: true,
        clearable: true,
        disabled: readOnly,
        placeholder: 'Reduction Type...',
        options: getEnumDropdownOptions(TermsPercentageOrDollar),
      },
    }),
    dueOnDeliverySecurityDeposit: currency({
      fieldLabel: 'Security Deposit',
      inputProps: {
        disabled: readOnly,
      },
    }),
    dueOnDeliveryOtherDeposit: currency({
      fieldLabel: 'Other Deposit',
      inputProps: {
        disabled: readOnly,
      },
    }),
    dueOnDeliveryUpfit: currency({
      fieldLabel: 'Upfit',
      inputProps: {
        disabled: readOnly,
      },
    }),
    dueOnDeliveryTransportAmount: currency({
      fieldLabel: 'Transport Amt',
      inputProps: {
        disabled: readOnly,
      },
    }),
    dueOnDeliveryLeaseOrigFee: currency({
      fieldLabel: 'Lease Orig Fee',
      inputProps: {
        disabled: readOnly,
      },
    }),
    dueOnDeliveryTms: currency({
      fieldLabel: 'TMS',
      inputProps: {
        disabled: readOnly,
      },
    }),
    dueOnDeliveryInsurance: currency({
      fieldLabel: 'Insurance',
      inputProps: {
        disabled: readOnly,
      },
    }),
    dueOnDeliveryGps: currency({
      fieldLabel: 'GPS',
      inputProps: {
        disabled: readOnly,
      },
    }),
    dueOnDeliveryAdditionalFeeDescription1: input({
      fieldLabel: 'Additional Fee Description 1',
      inputProps: {
        disabled: readOnly,
      },
    }),
    dueOnDeliveryAdditionalFeeAmount1: currency({
      fieldLabel: 'Additional Fee Amount 1',
      inputProps: {
        disabled: readOnly,
      },
    }),
    dueOnDeliveryAdditionalFeeDescription2: input({
      fieldLabel: 'Additional Fee Description 2',
      inputProps: {
        disabled: readOnly,
      },
    }),
    dueOnDeliveryAdditionalFeeAmount2: currency({
      fieldLabel: 'Additional Fee Amount 2',
      inputProps: {
        disabled: readOnly,
      },
    }),
    lessTradeInEquity: currency({
      fieldLabel: 'Less Trade-In Equity',
      inputProps: {
        disabled: readOnly,
      },
    }),
    term: input({
      fieldLabel: 'Term',
      required: true,
      inputProps: {
        placeholder: '12-60',
        disabled: readOnly,
      },
    }),
    apr: input({
      fieldLabel: 'APR',
      inputProps: {
        disabled: readOnly,
      },
    }),
    cashFlow: currency({
      fieldLabel: 'Cash Flow',
      inputProps: {
        disabled: readOnly,
      },
    }),
    calculateProrate: checkbox({
      fieldLabel: 'Calculate Prorate',
      inputProps: {
        toggle: true,
        disabled: readOnly,
      },
    }),
    contractBeginDate: datepicker({
      fieldLabel: 'Contract Begin Date',
      defaultValue: '',
      inputProps: {
        showClearDate: true,
        disabled: readOnly,
        maxYear: new Date().getFullYear() + 1,
      },
    }),
    showOnCustomerWorksheet: checkbox({
      fieldLabel: 'Show on Customer Worksheet',
      inputProps: {
        toggle: true,
        disabled: readOnly,
      },
    }),
    msrp: currency({
      fieldLabel: 'MSRP',
      inputProps: {
        disabled: readOnly,
      },
    }),
    invoice: currency({
      fieldLabel: 'Invoice',
      inputProps: {
        disabled: readOnly,
      },
    }),
  });
  const termsFormMutators = {
    setTax: (arg, state, utils) => {
      utils.changeValue(state, fields.salesTax.fieldName, () => arg[0]);
    },
    setTaxState: (arg, state, utils) => {
      utils.changeValue(state, fields.stateTaxId.fieldName, () => arg[0]);
    },
    setTaxCounty: (arg, state, utils) => {
      utils.changeValue(state, fields.countyTaxId.fieldName, () => arg[0]);
    },
    setTaxCity: (arg, state, utils) => {
      utils.changeValue(state, fields.cityTaxId.fieldName, () => arg[0]);
    },
    clearTax: (arg, state, utils) => {
      utils.changeValue(state, fields.cityTaxId.fieldName, () => null);
      utils.changeValue(state, fields.countyTaxId.fieldName, () => null);
      utils.changeValue(state, fields.stateTaxId.fieldName, () => null);
      utils.changeValue(state, fields.salesTax.fieldName, () => 0);
      utils.changeValue(
        state,
        fields.salesTaxOverrideAmount.fieldName,
        () => 0
      );
      utils.changeValue(state, fields.salesTaxType.fieldName, () => null);
    },
    setAllInitialValues: (arg, state, utils) => {
      Object.entries(arg[0]).forEach((keyValuePair) => {
        const fieldName = keyValuePair[0];
        const fieldValue = keyValuePair[1];
        utils.changeValue(state, fieldName, () => fieldValue);
      });
    },
  };
  return {fields, termsFormMutators};
};

const styles = css`
  .ui.header {
    padding-top: 0 !important;
  }

  .ui.medium.header {
    margin-bottom: 1rem !important;
  }

  .calculations {
    margin: 0 2rem 2rem 0;
    z-index: 5;
    position: sticky;
    top: -1px;
    background-color: white;
    width: 100%;
    padding: 2rem 0rem;
    border-bottom: 1px solid lightgray;
  }

  .ui.disabled.input,
  .ui.input:not(.disabled) input[disabled] {
    opacity: 1 !important;
  }

  .test-button {
    width: inherit;
  }
`;
