import React, {useMemo, useEffect} from 'react';
import {useForm, useFormState} from 'react-final-form';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync, useAsyncFn, useAsyncRetry} from 'react-use';
import {Divider} from 'semantic-ui-react';
import {UsersService, CreateUserRequest} from '../api/generated';
import {RoleTemplates} from '../api/generated/enums';
import {getOptionDtoDropdownOptions} from '../api/generated/utils';
import {BasicPage} from '../basic-page';
import {AsyncStateContainer} from '../components/async-state-container';
import {ConfirmButton, DeleteButton} from '../components/confirm-button';
import {useCustomerContext} from '../customer-context';
import {Form} from '../forms';
import {ArrayMutators} from '../forms/field-arrays';
import {
  dropdown,
  fieldConfig,
  getDefaults,
  input,
  RawFieldConfig,
} from '../forms/schema-utils';
import {SubmitError} from '../forms/submit-error';
import {useNotification} from '../hooks/use-notifications';
import {routes} from '../routes';
import {Alert} from '../components/alert';
import {css} from '@emotion/core';
import {useRequireAuthorization} from '../auth/require-authorization';

const useFields = ({customers, roles}, disabled = false) => {
  const [{currentCustomerId}] = useCustomerContext();

  return fieldConfig<CreateUserRequest>({
    firstName: input({
      fieldLabel: 'First Name',
    }),
    lastName: input({
      fieldLabel: 'Last Name',
    }),
    emailAddress: input({
      fieldLabel: 'Email Address',
    }),
    customers: dropdown({
      fieldLabel: 'Access Restricted to Customer(s)',
      inputProps: {
        options: getOptionDtoDropdownOptions(customers ?? []),
        selection: true,
        clearable: true,
        search: true,
        multiple: true,
        disabled: disabled,
      },
      defaultValue: [currentCustomerId],
    }),
    role: dropdown({
      fieldLabel: 'Role',
      inputProps: {
        options: getOptionDtoDropdownOptions(roles ?? []),
        selection: true,
        disabled: disabled,
      },
    }),

    // This one is not really an input, but I am just using it's
    // generated props to use with the FieldArray below
    claims: input({
      fieldLabel: 'Claims',
    }),
  });
};

const mutators = ArrayMutators();

const breadcrumbs = [
  {title: 'Users', url: routes.users.index},
  {title: 'Create'},
];

export const UserCreate = () => {
  const history = useHistory();
  const notifications = useNotification();

  const fetchOptions = useAsync(async () => {
    const response = await UsersService.getCreateOptions();
    if (response.hasErrors) {
      return null;
    }
    return response.data;
  });

  const options = fetchOptions.value || undefined;

  const fields = useFields({
    customers: options?.customers,
    roles: options?.roles,
  });

  const defaultValues = useMemo(() => {
    if (fields) {
      return getDefaults(fields);
    }
  }, [fields]);

  const onSubmit = async (values) => {
    const response = await UsersService.create({body: values});
    if (response.hasErrors) {
      return response;
    }

    notifications.success('User Created');
    history.push(routes.users.index);
  };

  return (
    <BasicPage title={breadcrumbs}>
      <AsyncStateContainer {...fetchOptions}>
        <Form.Container>
          <Form
            initialValues={defaultValues}
            mutators={mutators}
            onSubmit={onSubmit}
            render={() => (
              <>
                {fields && (
                  <FormFields
                    fields={fields}
                    permissions={options?.permissions ?? []}
                  />
                )}
                <Divider />
                <div className="form-actions">
                  <Form.Button type="submit" primary>
                    Create User
                  </Form.Button>
                  <Form.Button secondary as={Link} to={routes.users.index}>
                    Cancel
                  </Form.Button>
                </div>
              </>
            )}
          />
        </Form.Container>
      </AsyncStateContainer>
    </BasicPage>
  );
};

export const UserDetails = () => {
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();
  const id = Number(match.params.id);
  const notifications = useNotification();

  const fetchOptions = useAsync(async () => {
    const response = await UsersService.getUpdateOptions({id: id});
    if (response.hasErrors) {
      return null;
    }
    return response.data;
  });

  const options = fetchOptions.value || undefined;

  const fetchUser = useAsyncRetry(async () => {
    const {data} = await UsersService.getById({
      id,
    });
    return data;
  }, [id]);

  const fields = useFields(
    {
      customers: options?.customers,
      roles: options?.roles,
    },
    fetchUser.value?.isDeleted
  );

  const user = fetchUser.value || undefined;
  const userName = user?.claims ? `${user.firstName} ${user.lastName}` : '';
  const userDeleted = user?.isDeleted;

  const userIsAuthorizedToRestoreUsers = useRequireAuthorization({
    role: 'Mobilease Admin',
  });

  if (user && options) {
    user.customers = user.customers.filter((x) =>
      options.customers.map((y) => y.value).includes(x)
    );
  }

  const breadcrumbs = useMemo(
    () => [{title: 'Users', url: routes.users.index}, {title: userName}],
    [userName]
  );

  const onSubmit = async (values) => {
    const response = await UsersService.update({id, body: values});
    if (response.hasErrors) {
      return response;
    }
    notifications.success('User Updated');
    history.push(routes.users.index);
  };

  const [deleteUserState, deleteUser] = useAsyncFn(async () => {
    const response = await UsersService.deleteById({id});
    if (response.hasErrors) {
      notifications.error('Unable to delete user');
    } else {
      notifications.success('User successfully deleted');
      history.push(routes.users.index);
    }
  });

  const [restoreUserState, restoreUser] = useAsyncFn(async () => {
    const response = await UsersService.restoreById({id});
    if (response.hasErrors) {
      notifications.error('Unable to restore user');
    } else {
      notifications.success('User successfully restored');
      history.push(routes.users.index);
    }
  }, [history, id, notifications]);

  return (
    <BasicPage title={breadcrumbs}>
      <AsyncStateContainer {...fetchUser}>
        <Form.Container>
          <Form
            disabled={userDeleted}
            initialValues={user}
            mutators={mutators}
            onSubmit={onSubmit}
            render={() => (
              <>
                {userDeleted && (
                  <Alert
                    content={
                      <>
                        This user has been deleted and is currently inactive.{' '}
                        <>
                          {userIsAuthorizedToRestoreUsers && (
                            <ConfirmButton
                              text="Restore User"
                              loadingText="Restoring..."
                              confirmText="Are you sure?"
                              confirmPosition="right center"
                              onConfirm={restoreUser}
                              loading={restoreUserState.loading}
                              className=""
                              css={css`
                                margin-left: 1rem !important;
                              `}
                              secondary
                            />
                          )}
                        </>
                      </>
                    }
                    negative
                  />
                )}

                {fields && (
                  <FormFields
                    fields={fields}
                    permissions={options?.permissions ?? []}
                    disabled={fetchUser.value?.isDeleted}
                    phoneNumber={fetchUser.value?.phoneNumber}
                  />
                )}
                <Divider />
                <div className="form-actions">
                  <Form.Button type="submit" primary>
                    Update User
                  </Form.Button>
                  <Form.Button secondary as={Link} to={routes.users.index}>
                    Cancel
                  </Form.Button>

                  <DeleteButton
                    onConfirm={deleteUser}
                    loading={deleteUserState.loading}
                  />
                </div>
              </>
            )}
          />
        </Form.Container>
      </AsyncStateContainer>
    </BasicPage>
  );
};

const FormFields: React.FC<{
  fields: RawFieldConfig<CreateUserRequest>;
  permissions: string[];
  disabled?: boolean;
  phoneNumber?: string;
}> = ({fields, permissions, disabled, phoneNumber}) => {
  const form = useForm();
  const {values, initialValues} = useFormState();
  const userPhone = useMemo(() => {
    return {value: phoneNumber};
  }, [phoneNumber]);

  useEffect(() => {
    if (values?.role !== initialValues?.role) {
      form.change(fields.claims.fieldName, RoleTemplates[values.role]);
    } else {
      form.change(fields.claims.fieldName, initialValues.claims);
    }
  }, [
    fields.claims.fieldName,
    form,
    initialValues.claims,
    initialValues?.role,
    values.role,
  ]);

  return (
    <>
      <Form.Section title="User Details">
        <Form.Row proportions={[1, 1]}>
          <Form.Input fieldConfig={fields.firstName} />
          <Form.Input fieldConfig={fields.lastName} />
        </Form.Row>
        <Form.Row proportions={[1, 1]}>
          <Form.Input fieldConfig={fields.emailAddress} />
          <Form.Input
            fieldName="phoneNumber"
            fieldLabel="Phone Number"
            input={userPhone}
            disabled
          />
        </Form.Row>
      </Form.Section>
      <Form.Section title="Permissions">
        <Form.Row proportions={[1, 1]}>
          <Form.Dropdown fieldConfig={fields.customers} />
        </Form.Row>
        <Form.Row proportions={[1, 1]}>
          <Form.Dropdown fieldConfig={fields.role} />
        </Form.Row>
        <Form.Row>
          <Form.MultiSelectCheckbox
            fieldName={fields.claims.fieldName}
            options={permissions.map((x) => x)}
            fieldLabel={fields.claims.fieldLabel}
            disabled={disabled}
          />
        </Form.Row>
        <SubmitError name={fields.claims.fieldName} />
      </Form.Section>
    </>
  );
};
